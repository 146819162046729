import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "./layout"
import Img from "gatsby-image"
import Patreon from "./patreon"

export default function PageTemplate({ data: { mdx } }) {
  const imageRoot = mdx.frontmatter.featuredImage
  let featuredImgFluid = undefined
  let featuredImgURL = undefined
  if (imageRoot){
    featuredImgFluid = imageRoot.childImageSharp.fluid
    featuredImgURL = imageRoot.publicURL
  }
  return (
    <Layout 
      title={mdx.frontmatter.title}
      description={mdx.frontmatter.excerpt}
      image={featuredImgURL}
      pathname={mdx.frontmatter.slug}
      isArticle={true}
      publicationDate={mdx.frontmatter.publicationDate}
      lastUpdated={mdx.frontmatter.lastUpdated}>
      <article className="markdown">
        <div className="px-8 py-10 md:px-24 md:py-8">
          <div className="md:max-w-3xl md:mx-auto">
            {imageRoot && 
            <Img fluid={featuredImgFluid} alt={mdx.frontmatter.title} />}
            <div className="flex justify-center">
              <h1>{mdx.frontmatter.title}</h1>
            </div>
            <MDXRenderer>{mdx.body}</MDXRenderer>
            <div>
              <Patreon />
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        excerpt
        slug
        publicationDate
        lastUpdated
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`